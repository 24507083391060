<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-users small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Siswa
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  v-model="pageLength"
                  class="w-auto mr-1"
                  :options="options"
                />
              </b-col>
              <b-col></b-col>
              <b-col>
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Filter</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1 search-min-width"
                    />

                    <!-- Export Button -->
                    <b-button
                      variant="warning"
                      class="d-flex"
                      @click="downloadData()"
                    >
                      <i
                        v-if="!isButtonExportLoading"
                        class="ti ti-file-export small-gap-right"
                      />
                      <b-spinner
                        v-if="isButtonExportLoading"
                        class="small-gap-right"
                        small
                      />
                      Export
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- table -->
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="rows"
              :totalRows="total"
              :isLoading.sync="isLoading"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.per_page,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: serverParams.order_field, type: serverParams.order_direction },
              }"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'fullname'"
                  class="text-nowrap"
                >
                  <b-avatar
                    variant="light-primary"
                    :src="props.row.avatar"
                    class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.fullname }}</span>
                </span>

                <!-- Column: Sekolah -->
                <span
                  v-else-if="props.column.field === 'classroom'"
                  class="d-flex justify-content-start"
                >
                  <div v-if="props.row.classroom.length > 0">
                    <b-badge
                      variant="success"
                      class="small-gap-right text-white"
                    >
                      {{ props.row.classroom }}
                    </b-badge>
                  </div>
                  <div v-if="props.row.classroom.length === 0">
                    <small>Belum Punya Kelas</small>
                  </div>
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-start"
                >
                  <b-button
                    variant="warning"
                    size="sm"
                    class="mr-1"
                    :to="{
                      name: checkAuthorizeRole('students-edit'),
                      params: { id: props.row.id },
                    }"
                  >
                    <i class="ti ti-pencil" />
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mr-1"
                    @click="handleDelete(props.row)"
                  >
                    <i class="ti ti-trash" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="serverParams.page"
                      :total-rows="total"
                      :per-page="serverParams.per_page"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Import modal -->
    <student-import-modal @refresh-table="loadItems" />
  </div>
</template>

<script>
// *Utils
import { pascalCase, avatar } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'
import client from '@/libs/http/axios-config'

// *Component
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StudentImportModal from './StudentImportModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,

    VueGoodTable,
    StudentImportModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // State
      isLoading: true,
      buttonLoadingId: null,
      isButtonExportLoading: false,

      // Filter
      selectedClassroom: 'Semua Kelas',
      classroomList: [],

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 10,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Siswa',
          field: 'fullname',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Telepon',
          field: 'phone',
        },
        {
          label: 'Sekolah',
          field: 'school',
        },
      ],
      rows: [],
      serverParams: {
        order_field: 'name',
        order_direction: 'asc',
        page: 1,
        per_page: 10,
        role: "Student",
        status: "Active",
      },
    }
  },
  async mounted() {
    this.loadItems();
  },
  created() {
    this.debouncedSearch = this.debounce(this.onSearch, 500); // Adjust the debounce delay (e.g., 500ms)
  },
  watch: {
    searchTerm: {
      handler: function (value) {
        this.debouncedSearch(value);
      },
      immediate: false, // Set this to true if you want to trigger the function immediately on component mount
    },
  },
  methods: {
    pascalCase,
    checkAuthorizeRole,
    debounce(func, delay) {
      let timeoutId;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      };
    },
    showImportModal() {
      this.$bvModal.show('student-import-modal')
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    async handleExport() {
      this.isButtonExportLoading = true
      await client
        .get('school-admin/users-download?type=Student', {
          responseType: 'blob',
        })
        .then((response) => {
          this.isButtonExportLoading = false

          const blob = new Blob([response.data], { type: 'application/xlsx' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'Data Siswa.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((error) => {
          this.isButtonExportLoading = false
          this.showToast('error', 'XIcon', 'Gagal', error.response.data.message)
        })
    },
    async downloadData() {
      this.isButtonExportLoading = true
      const rows = await client
        .get("admin/users", { params: { role: 'Student', per_page: 9999 } })
        .then((response) => {
          const students = response.data.data.data;
          return students.map((student) => ({
            id: student.id,
            name: pascalCase(student.name),
            avatar: student.avatar,
            phone: student.phone,
            email: student.email,
            school: student.school?.name,
          }));
        })
        .catch((error) => {
          this.isButtonExportLoading = false
          this.showToast(
            "error",
            "XIcon",
            "Gagal",
            "Data Gagal Dimuat, Silahkan Coba Lagi"
          );
          return []
        });
          this.isButtonExportLoading = false
      const data = XLSX.utils.json_to_sheet(rows)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'data_siswa.xlsx')
    },

    // Showing sweetalert when delete user
    handleDelete(user) {
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Data yang dihapus tidak dapat dikembalikan',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.buttonLoadingId = user.id
          this.deleteStudent(user.id)
        }
      })
    },

    // Delete student endpoint
    async deleteStudent(studentId) {
      const data = new FormData()
      data.append('_method', 'PUT')
      await client.post(`/admin/users/${studentId}`, data).then(() => {
        this.loadItems().then(() => {
          this.buttonLoadingId = ''
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Siswa berhasil dihapus'
          )
        })
      })
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },

    onSearch(search) {
      this.serverParams.search = search
      this.loadItems();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      let field =  params[0].field
      let type = params[0].type === 'desc' ? 'desc' : 'asc'
      if (this.serverParams.order_field !== field || this.serverParams.order_direction !== type) {
        this.updateParams({ order_field: field, order_direction: type });
        this.loadItems();
      }
    },


    // Refresh data
    async loadItems() {
      this.isLoading = true

      const filterbyClassroom =
        this.selectedClassroom === 'Semua Kelas' ? '' : this.selectedClassroom

      await client
        .get(
          `/admin/users`, {params: this.serverParams}
        )
        .then((response) => {
          const students = response.data.data.data

          const listOfStudents = students.map((student) => {
            return {
              id: student.id,
              fullname: pascalCase(student.name),
              avatar: student.avatar,
              phone: student.phone,
              school: student.school?.name,
              email: student.email,
            }
          })

          this.rows = listOfStudents
          this.total = response.data.data.total;
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false

          this.showToast(
            'error',
            'XIcon',
            'Gagal',
            'Data Gagal Dimuat, Silahkan Coba Lagi'
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}
</style>
